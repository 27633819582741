import React from 'react'

interface Props {
  styles: { [k: string]: string }
}

const AvisoLegalPt = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Aviso legais</h1>
    <p>
      Esta página web é de propiedade da COVIPNEUS LDA (doravante RODI)
      sociedade por quotas com sede na Zona Industrial do Fundão, Lote 4,
      Fundão, 6230 483, freguesia do Fundão, concelho do Fundão, com o número
      único de matrícula junto da Conservátoria do Registo Comercial e de pessoa
      colectiva 500.228.264.
    </p>
    <br></br>
    <p>
      Para qualquer pergunta ou proposta, entre em contacto connosco pelo
      telefone 275779015 ou pelo email apoioaocliente@rodimotor.pt
    </p>
    <br></br>
    <p>
      Esta página Web é regida pela regulamentação exclusivamente aplicável em
      Portugal, estando sujeita a ela, tanto os nacionais como os estrangeiros
      que utilizem esta Web.
    </p>
    <p>
      O acesso à nossa página Web pelo UTILIZADOR é gratuito e está condicionado
      à leitura prévia e aceitação integral, expressa e sem reservas das
      presentes CONDIÇÕES GERAIS DE UTILIZAÇÃO vigentes no momento do acesso,
      que aconselhamos sejam lidas atentamente. O UTILIZADOR ao fazer uso do
      nosso portal, conteúdo ou serviços, aceita e submete-se expressamente às
      condições gerais de utilização do mesmo. Se o utilizador não estiver de
      acordo com as presentes condições de utilização, deverá abster-se de
      utilizar este portal e operar através do mesmo.
    </p>
    <p>
      O fornecimento de dados pessoais através do nosso portal requer uma idade
      mínima de 14 anos ou, quando apropriado, capacidade jurídica suficiente
      para contratar.
    </p>
    <p>
      A qualquer momento, poderemos modificar a apresentação e a configuração da
      nossa Web, expandir ou reduzir os serviços e inclusive removê-lo da Rede,
      bem como os serviços e conteúdos fornecidos, tudo isso de forma unilateral
      e sem aviso prévio.
    </p>

    <h2 className={styles.section_title}>
      <strong>1. PROPRIEDADE INTELECTUAL</strong>
    </h2>
    <p>
      Todos os conteúdos, textos, imagens e códigos fonte da propriedade da RODI
      ou de terceiros para os quais os seus direitos de exploração foram
      adquiridos e estão protegidos pelos direitos de propriedade intelectual e
      industrial.
    </p>
    <p>
      O utilizador só tem o direito a uma utilização privada dos mesmos, sem
      fins lucrativos, e precisa de autorização expressa para modificá-los,
      reproduzi-los, explorá-los, distribui-los ou exercer qualquer direito
      pertencente ao seu titular.
    </p>
    <p>
      RODI é uma marca registada e está proibida a reprodução, imitação,
      utilização ou inserção desta marca sem a nossa devida autorização.
    </p>
    <p>
      O estabelecimento de ligações ao nosso portal não confere nenhum direito
      sobre o mesmo. Da mesma forma, o simples fato de estabelecer uma ligação à
      nossa página Web não confere o direito de se atribuir a categoria de
      colaborador ou sócio.
    </p>
    <p>
      A RODI fica exonerada da responsabilidade por qualquer reclamação
      referente aos direitos de propriedade intelectual dos artigos e imagens
      publicados por terceiros no seu portal.
    </p>
    <p>
      Está absolutamente proibida a imitação total ou parcial do nosso portal.
    </p>

    <h2 className={styles.section_title}>
      <strong>2. CONDIÇÕES DE ACESSO E UTILIZAÇÃO</strong>
    </h2>
    <p>
      O acesso à nossa página Web é gratuito e não requer adesão ou registo
      prévio. No entanto, determinados serviços são de acesso restrito para
      determinados utilizadores registados e exigem identificação por
      palavras-passe. Esses serviços serão devidamente identificados na Web.
    </p>
    <p>
      O envio de dados pessoais implica a aceitação expressa pelo UTILIZADOR de
      nossa política de privacidade.
    </p>
    <p>
      O utilizador deve aceder à nossa página Web de acordo com a boa-fé, as
      regras de ordem pública e as presentes Condições Gerais de Utilização. O
      acesso ao nosso site é feito sob única e exclusiva responsabilidade do
      utilizador, que será responsável em todos os casos por quaisquer danos que
      possam ser causados a terceiros ou a nós mesmos.
    </p>
    <p>
      O utilizador tem expressamente proibida utilização e obtenção dos serviços
      e conteúdos oferecidos nesta página Web através de métodos diferentes aos
      estipulados nas presentes condições de utilização.
    </p>
    <p>
      O UTILIZADOR está proibido de realizar qualquer ação no nosso portal que
      cause uma sobrecarga excessiva no funcionamento dos nossos sistemas
      informáticos, bem como a introdução de vírus, instalação de robôs ou
      software que altere o funcionamento normal da nossa Web ou possa causar
      danos aos nossos sistemas informáticos.
    </p>
    <p>
      Tendo em conta a impossibilidade de exercer um controlo sobre as
      informações, conteúdos e serviços contidos em outras páginas Web que
      possam ser acedidas através das ligações que o nosso site coloca à
      disposição, informamos que a RODI fica isenta de qualquer responsabilidade
      por danos de qualquer tipo que possam surgir da utilização dessas páginas
      Web, fora de nossa empresa, pelo utilizador.
    </p>
    <p>
      A RODI reserva-se o direito unilateral e sem aviso prévio de cancelar
      qualquer UTILIZADOR que a organização compreenda que tenha violado as
      condições que regem a utilização da nossa página Web, sem que o UTILIZADOR
      tenha o direito de fazer qualquer tipo de reclamação contra este tipo de
      ação. Da mesma forma, reserva-se o direito de exercer as ações legais
      adequadas contra aqueles que violarem estas condições gerais de
      utilização, aceitando o UTILIZADOR que a não abertura dessas ações não
      constitui renúncia formal, permanecendo as mesmas em vigor até os prazos
      legais de prescrição das infrações.
    </p>

    <h2 className={styles.section_title}>
      <strong>3. POLÍTICA DE PRIVACIDADE</strong>
    </h2>
    <p>
      A confidencialidade e a segurança são valores fundamentais da RODI e,
      consequentemente, assumimos o compromisso de garantir a privacidade do
      Utilizador em todos os momentos e de não recolher informações
      desnecessárias.
    </p>
    <p>
      O fornecimento de dados pessoais através do nosso portal requer uma idade
      mínima de 14 anos e a aceitação expressa da nossa Política de Privacidade.
    </p>

    <h2 className={styles.section_title}>
      <strong>4. Responsabilidades</strong>
    </h2>
    <p>
      Ao disponibilizar esta página Web ao utilizador, queremos oferecer um
      serviço de qualidade, com a máxima diligência na prestação do mesmo, bem
      como nos meios tecnológicos utilizados. No entanto, não responderemos à
      presença de vírus e outros elementos que possam danificar de alguma
      maneira o sistema informático do utilizador.
    </p>
    <p>
      A RODI não garante que a disponibilidade do serviço seja contínua e
      ininterrupta, devido a circunstâncias causadas por problemas na Internet,
      avarias nos dispositivos informáticos ou outras circunstâncias
      imprevisíveis, de maneira que o UTILIZADOR aceita suportar essas
      circunstâncias dentro de limites razoáveis, pelo que renuncia
      expressamente a reclamar à RODI quaisquer responsabilidades por possíveis
      falhas, erros e utilização do serviço.
    </p>
    <p>
      O UTILIZADOR assume toda a responsabilidade decorrente da utilização da
      nossa página Web, sendo o único responsável por qualquer efeito direto ou
      indireto que seja derivado sobre a página Web, incluindo, mas não limitado
      a, qualquer resultado económico, técnico e/ou jurídico adverso, bem como a
      defraudação das expectativas geradas pelo nosso portal, obrigando-se o
      utilizador a manter a RODI indemne por quaisquer reclamações derivadas,
      direta ou indiretamente, de tais fatos.
    </p>
    <p>
      A RODI não garante a exatidão, veracidade e vigência dos conteúdos desta
      página Web, sejam eles próprios, publicados por terceiros ou vinculáveis a
      outros sites, ficando totalmente exonerada de qualquer responsabilidade
      decorrente da utilização dos mesmos.
    </p>
    <p>
      A RODI fica exonerada de qualquer responsabilidade decorrente de qualquer
      reclamação, incluindo o pagamento de honorários advocatícios, processos
      judiciais e reclamações originadas por terceiros pela violação por parte
      do UTILIZADOR das nossas condições de utilização, acesso e política de
      privacidade, ou qualquer outra reclamação pelo incumprimento da legislação
      em vigor.
    </p>
    <p>
      O UTILIZADOR reconhece que entendeu todas as informações sobre as
      condições de utilização do nosso portal e que são suficientes para a
      exclusão do erro nas mesmas e, portanto, aceita-as integral e
      expressamente.
    </p>
    <p>
      O UTILIZADOR está plenamente ciente de que a simples navegação através
      desta página Web, bem como a utilização dos seus serviços, implica a
      aceitação destas condições de utilização.
    </p>
    <p>
      Tudo o que está relacionado com a nossa página Web é regido exclusivamente
      pelas leis. Em caso de qualquer tipo de discrepância ou diferença entre as
      partes em relação à interpretação e conteúdo da presente página Web, todas
      as partes se submetem, renunciando expressamente a qualquer outra
      jurisdição, aos tribunais"
    </p>

    <h2 className={styles.section_title}>
      <strong>5. VIGÊNCIA DAS CONDIÇÕES GERAIS DE ACESSO À WEB</strong>
    </h2>
    <p>
      Estas Condições Gerais de Utilização foram modificadas em 10/03/2022. A
      qualquer momento, podemos implementar modificações: verifique a data de
      emissão toda vez que se ligar à nossa Página Web e terá a certeza de que
      não houve nenhuma modificação que lhe diga respeito.
    </p>
    <p>
      Para qualquer pergunta sobre as Condições de Utilização da nossa página
      Web, pode entrar em contacto connosco através dos dados mencionados acima
      ou com a Lant Advogados em info@lant-abogados.com, ou em{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lant-abogados.com/">
        www.lant-abogados.com
      </a>
      .
    </p>
  </div>
)

const AvisoLegalContents = ({ ...props }: Props) => <AvisoLegalPt {...props} />

export default AvisoLegalContents
